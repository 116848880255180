import React from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';

import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Layout from '../components/Layout';
import CallToAction from '../components/CallToAction';
import Seo from '../components/seo';

export const Head = () => (
  <Seo
    title="Pricing"
    description="The easiest way to create habits in Slack. Track goals directly in Slack"
  />
);

const hobbyFeatures = ['Up to 20 users', 'Reminders'];

const scaleFeatures = ['Unlimited Users', 'Premium support'];

const growthFeatures = ['Up to 50 users', 'Reminders', 'Customize schedules'];

const faqs = [
  {
    id: 11,
    question: 'How do I upgrade?',

    answer:
      'Open the Doathing app in Slack, navigate to the home screen, and click the upgrade button to see a list of options.',
  },
  {
    id: 1,
    question: 'Can I try Doathing before committing to a subscription?',

    answer:
      'Yes! Doathing comes with a free 14-day trial, so you’ll have plenty of time to try it with your team. No credit card required. We also offer a 7-day extension if you need extra time getting the payment approved.',
  },
  {
    id: 2,
    question: 'What if I upgrade but then decide I don’t need the service?',

    answer:
      'No problem - you can cancel your subscription within 60 days for a full refund.',
  },
  {
    id: 3,
    question: 'What is considered a user?',

    answer:
      'A user is a unique Slack user included in one or more rotation groups in Doathing, not your entire Slack workspace. Doathing calculates on a weekly basis how many users you have.',
  },
  {
    id: 4,
    question: 'Do I need to enter a credit card to try Doathing?',
    answer: 'No, you can start your 14-day free trial without a credit card.',
  },
  {
    id: 5,
    question: 'How does Doathing protect my privacy?',
    answer:
      'Doathing requests the absolute minimum permissions to work by default. The permissions are to manage user groups, view user info, and send messages to where the Doathing app is invited.',
  },
  {
    id: 6,
    question: 'How do I cancel my subscription?',
    answer:
      "While we build out our subscription management, you'll need to contact support@tellspin.app. Eventually you'll be able to easily cancel your subscription from within the Doathing Slack app.",
  },
  {
    id: 7,
    question:
      'I’m interested in setting up an Enterprise plan or bulk discounts for my business. Where can I learn more?',

    answer: 'Contact dan@plainice.com',
  },
];

export default function Pricing() {
  const theme = useTheme();

  return (
    <Layout funnelString="pricing">
      <div style={{ backgroundColor: theme.palette.primary.main }}>
        <div className="p-1 px-4 text-center text-white sm:py-6 lg:py-8 sm:px-6 lg:px-8 lg:p-1">
          <Typography variant="h1" className="text-white">
            The easiest way to create lasting habits in Slack.
          </Typography>
        </div>

        <div className="bg-white">
          <div className="relative z-0">
            <div
              className="absolute inset-0 h-5/6 lg:h-2/3"
              style={{ backgroundColor: theme.palette.primary.main }}
            />
            <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="relative lg:grid lg:grid-cols-7">
                <div className="max-w-md mx-auto lg:mx-0 lg:max-w-none lg:col-start-1 lg:col-end-3 lg:row-start-2 lg:row-end-3">
                  <div className="flex flex-col h-full overflow-hidden rounded-lg shadow-lg lg:rounded-none lg:rounded-l-lg">
                    <div className="flex flex-col flex-1">
                      <div className="px-6 py-5 bg-white md:pb-1">
                        <div>
                          <h3
                            className="text-2xl font-medium text-center text-gray-900"
                            id="tier-hobby"
                          >
                            Team
                          </h3>
                          <div className="flex items-center justify-center mt-4">
                            <div className="text-xl font-medium text-gray-500">
                              Starts at
                            </div>
                          </div>
                          <div className="flex items-center justify-center mt-4 lg:pb-14">
                            <span className="flex items-start px-3 text-6xl tracking-tight text-gray-900">
                              <span className="mt-2 mr-2 text-4xl font-medium">
                                $
                              </span>
                              <span className="font-extrabold">20</span>
                            </span>
                            <span className="text-xl font-medium text-gray-500">
                              /month
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col justify-between flex-1 p-6 border-t-2 border-gray-100 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
                        <ul className="space-y-4">
                          {hobbyFeatures.map((feature) => (
                            <li key={feature} className="flex items-start">
                              <div className="flex-shrink-0">
                                <CheckIcon
                                  className="flex-shrink-0 w-6 h-6 text-green-500"
                                  aria-hidden="true"
                                />
                              </div>
                              <p className="ml-3 text-base font-medium text-gray-500">
                                {feature}
                              </p>
                            </li>
                          ))}
                        </ul>
                        <div className="mt-5">
                          <CallToAction
                            funnel="pricing-team"
                            text={() => (
                              <div className="flex items-center justify-center m-4">
                                <div className="text-2xl font-medium ">
                                  Start 14-day trial
                                </div>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="max-w-lg mx-auto mt-12 lg:mt-0 lg:max-w-none lg:mx-0 lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4">
                  <div className="relative z-10 rounded-lg shadow-xl">
                    <div
                      className="absolute inset-0 border-2 border-yellow-400 rounded-lg pointer-events-none"
                      aria-hidden="true"
                    />
                    <div className="absolute inset-x-0 top-0 transform translate-y-px">
                      <div className="flex justify-center transform -translate-y-1/2">
                        <span className="inline-flex px-4 py-1 text-sm font-semibold tracking-wider uppercase bg-yellow-400 rounded-full ">
                          Recommended
                        </span>
                      </div>
                    </div>
                    <div className="px-6 pt-12 pb-10 bg-white rounded-t-lg">
                      <div>
                        <h3
                          className="text-3xl font-semibold text-center text-gray-900 sm:-mx-6"
                          id="tier-growth"
                        >
                          Business
                        </h3>
                        <div className="flex items-center justify-center mt-4">
                          <div className="text-2xl font-medium text-gray-500">
                            Starts at
                          </div>
                        </div>
                        <div className="flex items-center justify-center mt-4">
                          <span className="flex items-start px-3 text-6xl tracking-tight text-gray-900 sm:text-6xl">
                            <span className="mt-2 mr-2 text-4xl font-medium">
                              $
                            </span>
                            <span className="font-extrabold">50</span>
                          </span>
                          <span className="text-2xl font-medium text-gray-500">
                            /month
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="px-6 pt-10 pb-8 border-t-2 border-gray-100 rounded-b-lg bg-gray-50 sm:px-10 sm:py-10">
                      <ul className="space-y-4">
                        {growthFeatures.map((feature) => (
                          <li key={feature} className="flex items-start">
                            <div className="flex-shrink-0">
                              <CheckIcon
                                className="flex-shrink-0 w-6 h-6 text-green-500"
                                aria-hidden="true"
                              />
                            </div>
                            <p className="ml-3 text-base font-medium text-gray-500">
                              {feature}
                            </p>
                          </li>
                        ))}
                      </ul>
                      <div className="py-20 ">
                        <CallToAction
                          funnel="pricing-business"
                          text={() => (
                            <div className="flex items-center justify-center m-4">
                              <div className="text-2xl font-medium ">
                                Start 14-day trial
                              </div>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="max-w-md mx-auto mt-10 lg:m-0 lg:max-w-none lg:col-start-6 lg:col-end-8 lg:row-start-2 lg:row-end-3">
                  <div className="flex flex-col h-full overflow-hidden rounded-lg shadow-lg lg:rounded-none lg:rounded-r-lg">
                    <div className="flex flex-col flex-1">
                      <div className="px-6 py-10 bg-white">
                        <div>
                          <h3
                            className="text-2xl font-medium text-center text-gray-900"
                            id="tier-scale"
                          >
                            Enterprise
                          </h3>
                        </div>
                        <div className="flex items-center justify-center mt-4">
                          <div className="text-2xl font-medium text-gray-500">
                            Starts at
                          </div>
                        </div>
                        <div className="flex items-center justify-center mt-4 ">
                          <span className="flex items-start px-3 text-6xl tracking-tight text-gray-900 sm:text-6xl">
                            <span className="mt-2 mr-2 text-4xl font-medium">
                              $
                            </span>
                            <span className="font-extrabold">200</span>
                          </span>
                          <span className="text-2xl font-medium text-gray-500">
                            /month
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col justify-between flex-1 p-6 border-t-2 border-gray-100 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
                        <ul className="space-y-3">
                          <li className="flex items-start">
                            <p className="ml-3 text-base font-medium text-gray-500 text-bold">
                              All the greatness of Business, and:
                            </p>
                          </li>
                          {scaleFeatures.map((feature) => (
                            <li key={feature} className="flex items-start">
                              <div className="flex-shrink-0">
                                <CheckIcon
                                  className="flex-shrink-0 w-6 h-6 text-green-500"
                                  aria-hidden="true"
                                />
                              </div>
                              <p className="ml-3 text-base font-medium text-gray-500">
                                {feature}
                              </p>
                            </li>
                          ))}
                        </ul>
                        <div className="">
                          <CallToAction
                            funnel="pricing-enterprise"
                            text={() => (
                              <div className="flex items-center justify-center m-4">
                                <div className="text-2xl font-medium ">
                                  Start 14-day trial
                                </div>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section id="faq">
        <Faq />
      </section>
    </Layout>
  );
}

function Faq() {
  return (
    <div className="bg-white">
      <div className="px-4 py-8 mx-auto max-w-7xl sm:py-16 sm:px-3 lg:px-4">
        <h2 className="text-3xl font-extrabold text-center text-gray-900">
          Frequently asked questions
        </h2>
        <div className="mt-12">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12 lg:grid-cols-3">
            {faqs.map((faq) => (
              <div key={faq.id}>
                <dt className="text-lg font-medium text-gray-900 leading-6">
                  {faq.question}
                </dt>
                <dd className="mt-2 text-base text-gray-500">{faq.answer}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
